/* ----- header ------------------- */
.twidget-container label{
  font-weight: normal;
}

.header {
  position: relative;
  background-color: #1d4c9e;
  padding: 20px 0 26px;
}

.header-top {
  margin-bottom: 56px;
}

.logo {
  display: inline-block;
  outline: none;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 1.3px;
}

.logo:hover {
  text-decoration: none;
  color: #fff;
}

.logo__img {
  margin-right: 3px;
}

.logo__txt {
  font-size: 18px;
  letter-spacing: 0.54px;
}

.logo > span {
  display: inline-block;
  vertical-align: middle;
}

.header-cont .mewtwo-widget .mewtwo-flights {
  padding: 0 !important;
}

.header-cont-tt {
  color: #fff;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 16px;
  h1, .title-site {
    margin-bottom: 1px;
    color: #fff;
    font-size: 34px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.header-cont-img {
  display: none;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .header {
    padding: 22px 0 34px;
    position: relative;
    //overflow: hidden;
  }
  .logo {
    letter-spacing: 0.54px;
  }
  .logo__img {
    margin-right: 1px;
  }
  .header-top {
    margin-bottom: 42px;
    top: 0;
    transition: top 0.5s ease-in-out;
    position: relative;
    z-index: 2;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  .header-cont-tt {
    margin-bottom: 31px;
    p {
      max-width: 710px;
    }
    h1 {
      margin-bottom: 1px;
    }
  }
  #wrapper.sticky {
    .header {
      padding-top: 100px;
    }
    .header-top {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      padding: 20px 0;
      background-color: #1d4fa1;
      z-index: 100;
      -webkit-animation-name: slideInDown;
      animation-name: slideInDown;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
  .header-cont-block {
    max-width: 665px;
  }
  .header-cont-img {
    position: absolute;
    display: block;
    left: 50%;
    margin-left: 27px;
    bottom: 0;
    height: 100%;
    width: 581px;
    overflow: hidden;
    img {
      min-height: 100%;
      width: auto;
      min-width: 100%;
      max-width: none;
    }
    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      background: url("../img/photo-gradient.png") no-repeat 50% 50%;
      width: 100%;
      height: 100%;
    }
  }
  .header-cont {
    position: relative;
    z-index: 2;
    .container {
      position: relative;
    }
  }
  /* end media min width 1000 pixels */
}

/* ----- END header --------------- */
/*                                  */
/*                                  */
