/* ----- Index page -------------------- */
.card-bl {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
.card-bl-item {
  padding: 20px 15px;
  border: 1px solid #f2f2f2;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  outline: none;
  min-width: 300px;
  max-width: 360px;
  &__img {
    flex-shrink: 0;
    margin-right: 20px;
    max-width: 125px; }
  &__txt {
    font-size: 16px;
    text-decoration: none;
    flex-grow: 1; } }

.card-name {
  line-height: 24px;
  color: #000000;
  font-weight: normal;
  margin-bottom: 5px;
  &-brand {
    font-weight: normal;
    line-height: 20px;
    color: #23aaea; } }

.push--1 {
  margin-bottom: 20px; }
.push--2 {
  margin-bottom: 30px; }
.push--3 {
  margin-bottom: 40px; }

.card-box-item__img {
  margin-bottom: 20px; }

.card-box-item {
  margin-bottom: 30px;
  &__txt {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    color: #000000;
    >span {
      color: #23aaea; } } }

.section-middle {
  padding: 40px 0; }

.icon-angl {
  background-position: 0 0;
  background-image: url(../img/icon/english.png);
  background-repeat: no-repeat;
  width: 18px;
  height: 12px;
  margin-bottom: 1px; }

.point-bl-item {
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px; }

.point-brand__img {
  margin-bottom: 20px; }

.point-brand__txt {
  font-size: 16px;
  line-height: 1.63;
  color: #000000;
  p {
    margin-bottom: 10px; } }

.point-brand-name {
  color: #23aaea;
  font-weight: bold; }

.point-img {
  &:last-child {
    border-bottom: none; } }

.point-img__img {
  margin-bottom: 20px; }

.point-tt {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 5px;
  color: #000000; }

a.point-tt {
  &:hover {
    text-decoration: underline;
    color: #000; } }

.point-data {
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 10px; }
/* Min width 480 pixels */
@media only screen and (min-width: 480px) {
  .card-bl-item {
    &__img {
      margin-right: 15px;
      max-width: 165px; } }

  .card-box {
    display: flex;
    justify-content: flex-start;
    margin-right: -1%;
    margin-left: -1%;
    flex-wrap: wrap;
    &-item {
      width: 48%;
      margin-right: 1%;
      margin-left: 1%;
      &__img {
        min-height: 75px;
        line-height: 75px; } } }
  /* end media min width 480 pixels */ }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .card-box {
    margin-right: -1%;
    margin-left: -1%;
    &-item {
      width: 23%;
      margin-right: 1%;
      margin-left: 1%; } }
  .card-bl-item {
    min-width: 360px; }

  .point-brand, .point-img {
    display: flex;
    justify-content: flex-start; }

  .point-brand__img {
    min-width: 220px;
    margin-right: 20px;
    flex-shrink: 0;
    max-width: 220px; }
  .point-brand__txt {
    flex-grow: 1;
    max-width: 100%; }

  .point-bl-item {
    margin-bottom: 35px;
    padding-bottom: 35px; }
  .point-brand__txt {
    p {
      margin-bottom: 0px; } }

  .point-img {
    padding-bottom: 25px;
    margin-bottom: 25px;
    &__img {
      margin-right: 30px;
      min-width: 220px;
      max-width: 220px;
      margin-bottom: 0; } }

  .section-middle {
    padding: 48px 0 50px; }

  .logo__txt {
    margin-left: 5px; }
  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .card-bl-item {
    min-width: 285px;
    width: 100%;
    max-width: 100%; }
  a.card-bl-item {
    &:hover {
      box-shadow: 0 5px 10px rgba(#000, 0.3);
      text-decoration: none; } }
  .card-bl-item__img {
    max-width: 110px; }
  .content-wrap.push--3 {
    margin-bottom: 100px; }

  .text-bl-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .btn {
      margin-top: auto; } }
  /* end media min width 1000 pixels */ }


/* ----- END index page /--------------- */
/*                                       */
/*                                       */
